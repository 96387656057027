import { EnvironmentSports } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments-sports/sportsbook/lvbetlv/environment.base';

export const environment: EnvironmentSports = {
  ...environmentBase,
  matchOfferApiUrl: 'https://offer.lvbet.lv/client-api/v5/',
  matchOfferSocketUrl: 'wss://offer-ws.lvbet.lv/_v5/ws/update/',
  matchOfferSocketMatchStatsUrl: 'wss://offer-ws-statistics.lvbet.lv/_v1/',
  bpApiUrl: 'https://betslips.lvbet.lv/client-betslips/v1/',
  bpApiUrlSystem: 'https://settings.lvbet.lv/client-system/v1/'
};

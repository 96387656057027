import { StatscoreVizuConfig } from '../types';

export const statscoreVizuConfigDesktop: StatscoreVizuConfig = {
  liveApiKeys: {
    match: '61f92e98f74c8d74cf61b00d',
    stats: '64ca018caa628434b0056684'
  },
  preApiKeys: {
    match: '64ca0188aa628434b0056683'
  }
};

export const statscoreVizuConfigMobile: StatscoreVizuConfig = {
  liveApiKeys: {
    match: '6448dbf89ff1c4b3e5cc7f7b',
    stats: '64ca018caa628434b0056684'
  },
  preApiKeys: {
    match: '64ca0188aa628434b0056683'
  }
};

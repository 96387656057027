import { Provider } from '@angular/core';
import { abiosConfigName } from '@bcf-v2-configurators/tokens/abios/lvbet/abios-config-name';
import { ABIOS_CONFIG_NAME } from '@bcf-v2-configurators/tokens/abios/token';
import { appMaintenanceUrl } from '@bcf-v2-configurators/tokens/app-maintenance/lvbetlv/app-maintenance-url';
import { APP_MAINTENANCE_URL } from '@bcf-v2-configurators/tokens/app-maintenance/token';
import { bonusesEnabled } from '@bcf-v2-configurators/tokens/bonuses/lvbetlv/bonuses-enabled';
import { BONUSES_ENABLED } from '@bcf-v2-configurators/tokens/bonuses/token';
import { casinoLink, liveCasinoLink } from '@bcf-v2-configurators/tokens/casino-links/lvbetlv/casino-links';
import { CASINO_FULL_URL, LIVE_CASINO_FULL_URL } from '@bcf-v2-configurators/tokens/casino-links/token';
import { myBetsListDateFormat } from '@bcf-v2-configurators/tokens/date-format/letsbetmd/my-bets-list-date-format';
import { MY_BETS_LIST_DATE_FORMAT } from '@bcf-v2-configurators/tokens/date-format/token';
import { defaultLang } from '@bcf-v2-configurators/tokens/default-lang/lvbetlv/default-lang';
import { DEFAULT_LANG } from '@bcf-v2-configurators/tokens/default-lang/token';
import { I18N_OPTIMOVE_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs-optimove-i18n/token';
import { I18N_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs/token';
import {
  editProfileAddressAvailableFieldsFn,
  editProfilePrivacyAndNotificationsAvailableFields
} from '@bcf-v2-configurators/tokens/edit-profile/lvbetcom/edit-profile-available-fields';
import {
  EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
  EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS
} from '@bcf-v2-configurators/tokens/edit-profile/token';
import { footerBrandName } from '@bcf-v2-configurators/tokens/footer/lvbetlv/footer-brand-name';
import { footerLinksFn } from '@bcf-v2-configurators/tokens/footer/lvbetlv/footer-links';
import { FOOTER_BRAND_NAME, FOOTER_LINKS } from '@bcf-v2-configurators/tokens/footer/token';
import { ladeskChatConfig } from '@bcf-v2-configurators/tokens/ladesk-chat/lvbetlv/ladesk-chat-config';
import { LADESK_CHAT_CONFIG } from '@bcf-v2-configurators/tokens/ladesk-chat/token';
import { lvbetcomLanguageSwitcherFlagMap } from '@bcf-v2-configurators/tokens/language-switcher/lvbetcom/lvbetcom-language-switcher-flag-map';
import { LANGUAGE_SWITCHER_FLAG_MAP } from '@bcf-v2-configurators/tokens/language-switcher/token';
import { lvisionBetBoosterApiKeys } from '@bcf-v2-configurators/tokens/lvision-bet-booster/lvbetlv/lvision-bet-booster-api-keys';
import { LVISION_BET_BOOSTER_API_KEYS } from '@bcf-v2-configurators/tokens/lvision-bet-booster/token';
import { mobileApplicationAppConfig } from '@bcf-v2-configurators/tokens/mobile-application/sportsbook/lvbetlv/config';
import { MOBILE_APPLICATION_APPS_CONFIG } from '@bcf-v2-configurators/tokens/mobile-application/token';
import { OPTIMOVE_SDK_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/optimove-sdk/tokens';
import { PUSH_NOTIFICATIONS_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/push-notifications/token';
import { statscoreVizuConfigMobile } from '@bcf-v2-configurators/tokens/statscore-vizu-config/lvbet/statscore-vizu-config';
import { STATSCORE_VIZU_CONFIG } from '@bcf-v2-configurators/tokens/statscore-vizu-config/token';

export const settingsProviders: Provider[] = [
  {
    provide: DEFAULT_LANG,
    useValue: defaultLang
  },
  {
    provide: APP_MAINTENANCE_URL,
    useValue: appMaintenanceUrl
  },
  {
    provide: I18N_DESC_LOADER,
    useValue: () =>
      import(
        /* webpackChunkName: "const-i18n-desc" */ '@bcf-v2-configurators/tokens/descs/lvbetlv/mobile-web/i18n-descs'
        // eslint-disable-next-line @typescript-eslint/typedef
      ).then((mod) => mod.i18nDesc)
  },
  {
    provide: EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS,
    useValue: editProfilePrivacyAndNotificationsAvailableFields
  },
  {
    provide: EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
    useValue: editProfileAddressAvailableFieldsFn
  },
  {
    provide: LADESK_CHAT_CONFIG,
    useValue: ladeskChatConfig
  },
  {
    provide: LANGUAGE_SWITCHER_FLAG_MAP,
    useValue: lvbetcomLanguageSwitcherFlagMap
  },
  {
    provide: STATSCORE_VIZU_CONFIG,
    useValue: statscoreVizuConfigMobile
  },
  {
    provide: ABIOS_CONFIG_NAME,
    useValue: abiosConfigName
  },
  {
    provide: CASINO_FULL_URL,
    useValue: casinoLink
  },
  {
    provide: LIVE_CASINO_FULL_URL,
    useValue: liveCasinoLink
  },
  {
    provide: BONUSES_ENABLED,
    useValue: bonusesEnabled
  },
  {
    provide: FOOTER_LINKS,
    useValue: footerLinksFn
  },
  {
    provide: FOOTER_BRAND_NAME,
    useValue: footerBrandName
  },
  {
    provide: MY_BETS_LIST_DATE_FORMAT,
    useValue: myBetsListDateFormat
  },
  {
    provide: MOBILE_APPLICATION_APPS_CONFIG,
    useValue: mobileApplicationAppConfig
  },
  {
    provide: PUSH_NOTIFICATIONS_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/push-notifications/lvbetlv/push-notifications-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.pushNotificationsConfigs
      )
  },
  {
    provide: OPTIMOVE_SDK_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/optimove-sdk/lvbetlv/sportsbook/mobile-web/optimove-sdk-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.optimoveSdkConfigs
      )
  },
  {
    provide: I18N_OPTIMOVE_DESC_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/descs-optimove-i18n/lvbetlv/sportsbook/i18n-optimove-desc').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.i18nOptimoveDesc
      )
  },
  {
    provide: LVISION_BET_BOOSTER_API_KEYS,
    useValue: lvisionBetBoosterApiKeys
  }
];
